import MainStore from "../../../modules/Main/MainStore.js";



const ItemTopAmount = ({ item }) => {
    return (
        <div className="s-container item-container">
            <div className="itemImage">
                <img src={`${MainStore.SERVER_ADDRESS}/wheat/items/${item.id}.png`} width={16} height={16}  alt={item.id} />
            </div>
            <div className="itemName">
                <a href={`http://default-squad.ru/wheat/item-chart-amount/minecraft:${item.id}`}>
                    {item.name || item.id.split("_").map((word) => word[0].toUpperCase() + word.substring(1)).join(" ")}
                </a>
            </div>
            <div className="itemAmount">
                {item.amount.toLocaleString().replaceAll(",", " ")}
            </div>
        </div>
    );
}

export default ItemTopAmount;