import {useContext, useEffect} from "react";
import {Spinner} from "react-bootstrap";

import {WheatItemsStoreContext} from "../../../App.js";

import {observer} from "mobx-react";

// COMPONENTS
import ItemTopAmount from "../../../components/Wheat/Items/ItemTopAmount.jsx";



const TopAmount = observer(() => {
    const WIS = useContext(WheatItemsStoreContext);

    useEffect(() => {
        const fetchData = async () => {
            await WIS.getItemsTopAmount();
        }

        fetchData();
    }, [WIS]);

    return (
        <div className="wheat__wrapper-content">
            <div className="wheat__list-title">
                ТОП по количеству
            </div>
            <div className="wheat-search">
                <input type="text" placeholder="Поиск" onChange={WIS.onChangeSearch} />
            </div>
            {WIS.itemsLoading ? <Spinner /> :
                WIS.isSorted ?
                    WIS.itemsSorted.map((item) => <ItemTopAmount key={item.id} item={item} />)
                    :
                    WIS.items.map((item) => <ItemTopAmount key={item.id} item={item} />)
            }
        </div>
    );
});

export default TopAmount;