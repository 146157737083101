import {CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis} from "recharts";


const Chart = ({ data }) => {
    return (
        <LineChart width={1800} height={720} data={data.values}>
            <CartesianGrid strokeDasharray="5 5"/>
            <XAxis dataKey="date"/>
            <YAxis/>
            <Tooltip/>
            <Line type="monotone" dataKey="amount" stroke="#ca82af"/>
        </LineChart>
    );
}

export default Chart;
