// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_wrapper__gX\\+Eq {
    font-family: ManropeMedium, "Segoe UI", sans-serif;
    color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.style_title__P0Gjo, .style_result__Hzsl8 {
    padding: 1em;
    font-size: 1.5em;
}

.style_inputBox__dIXKu {
    background: whitesmoke;
    color: black;
    max-width: 3em;
    display: flex;
    justify-content: center;
}

input {
    padding: 1em;
    font-size: 2em;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Other/Calculator/style.module.css"],"names":[],"mappings":"AAAA;IACI,kDAAkD;IAClD,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,sBAAsB;AAC1B;;AAEA;IACI,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,sBAAsB;IACtB,YAAY;IACZ,cAAc;IACd,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,YAAY;IACZ,cAAc;AAClB","sourcesContent":[".wrapper {\r\n    font-family: ManropeMedium, \"Segoe UI\", sans-serif;\r\n    color: white;\r\n    display: flex;\r\n    align-items: center;\r\n    flex-direction: column;\r\n}\r\n\r\n.title, .result {\r\n    padding: 1em;\r\n    font-size: 1.5em;\r\n}\r\n\r\n.inputBox {\r\n    background: whitesmoke;\r\n    color: black;\r\n    max-width: 3em;\r\n    display: flex;\r\n    justify-content: center;\r\n}\r\n\r\ninput {\r\n    padding: 1em;\r\n    font-size: 2em;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `style_wrapper__gX+Eq`,
	"title": `style_title__P0Gjo`,
	"result": `style_result__Hzsl8`,
	"inputBox": `style_inputBox__dIXKu`
};
export default ___CSS_LOADER_EXPORT___;
