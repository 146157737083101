import { useState, useContext, useEffect } from "react";
import { observer } from "mobx-react";
import { Button } from "react-bootstrap";

import NotificationCenter from "../../../modules/Main/NotificationsCenter.js";

import { UserStoreContext } from "../../../App.js";
import { BlogStoreContext } from "../../../App.js";

// COMPONENTS
import BlogPost from "./BlogPost.jsx";
import MainStore from "../../../modules/Main/MainStore.js";



const Blog = observer(() => {
    const [blogText, setBlogText] = useState("");

    const User = useContext(UserStoreContext);
    const BlogStore = useContext(BlogStoreContext);
    
    useEffect(() => {
        async function fetchData() {
            await BlogStore.updateBlog(User.userData.id);
            // {console.log(Math.floor(BlogStore.posts.length / 5))}
        }

        fetchData();
    }, []);

    const BlogSendPost = async () => {
        if (blogText === "") {
            return NotificationCenter.ShowInfo("Текст не может быть пустой");
        }

        setBlogText(MainStore.textFormatterOnlyText(blogText));

        let message = await BlogStore.sendBlog(blogText, User.userData.id);

        NotificationCenter.ShowSuccess(message);

        setBlogText("");
    }

    const getRandomArrayElement = (array) => array[Math.floor(Math.random() * array.length)];

    const getRandomProposal = () => {
        let proposals = [
            "Какое настроение у вас сегодня?",
            "Фляма дуан?",
            "fmt.Sprintf()",
            "Скажем нет говнокоду!",
            "Генерируем строку...",
            "Если вы хотите передать строку, используйте %s",
            "Тебе столько-то лет...",
            "Jyf djpdhfoftn cnhjre",
            "Эта строка подставляется сюда...",
            "Эта функция возвращает string, а мы хотели int",
            "Hello, Default Squad!",
            "Enter the club >",
            "if (text.length === 0) return `ПОШЁЛ НАХУЙ!`",
            "Flyama fyl dau",
            "Age >= 18",
            "Арбузное безумие",
            "Пиши свой текст, только есть одно условие...",
            "Туалет взорвался потому что Фляма сильно нахимичил в мастерской",
            "ААААААААААААААААААА Я ЁБНУТЫЙ БЕГИТЕ",
            "Я считаю что 45 это прекрасный возраст",
            "Опишите своё отношение к этому сайту",
            "Error 404"
        ]

        return getRandomArrayElement(proposals);
    }

    const onChangeBlogText = (event) => {
        let text = event.target.value;
        setBlogText(text);
    }

    const nextPage = () => {
        BlogStore.incrementPageMyPosts();
    }

    const prevPage = () => {
        BlogStore.decrementPageMyPosts();
    }


    return (
        <div className="blog">
            {BlogStore.currentPageMyPosts === 0 && <div className="blog__form">
                <div className="container-blog">
                    <div className="inputBox">
                        <div className="text-pre-show">
                            {MainStore.textFormatter(blogText) || "Предпросмотр текста будет здесь"}
                        </div>
                        <br />
                        <textarea id="blog-text-input" type="text" placeholder={getRandomProposal()} onChange={onChangeBlogText} />
                    </div>
                    <div className="inputBox">
                        <input style={{ margin: 0 }} type="submit" value="Отправить" id="btn001" onClick={BlogSendPost} />
                    </div>
                </div>
            </div>}

            <div className="buttons-group" style={{marginBottom: 10}}>
                <Button className="button" variant={"outline-secondary"} onClick={prevPage} disabled={BlogStore.currentPageMyPosts === 0}>
                    Предыдущая
                </Button>
                <Button className="button" variant={"outline-secondary"} onClick={nextPage} disabled={BlogStore.currentPageMyPosts + 1 === BlogStore.totalPagesMyPosts}>
                    Следующая
                </Button>
            </div>

            <div className="blog-posts">
                {!BlogStore.isShowAllPosts &&
                BlogStore.posts.slice(BlogStore.currentPageMyPosts * BlogStore.maxItemsOnPageMyPosts, (BlogStore.currentPageMyPosts + 1) * BlogStore.maxItemsOnPageMyPosts).map((post) => {
                    return <BlogPost key={post.id} post={post} BlogStore={BlogStore} isPublic={false} />
                })}
            </div>
        </div>
    );
});

export default Blog;