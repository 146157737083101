import { useContext } from "react";

import NotificationsCenter from "../../../modules/Main/NotificationsCenter.js";

// STORES
import { TitleStoreContext } from "../../../App.js";
import {Button} from "react-bootstrap";
import UserStore from "../../../modules/Squad/UserStore.js";



const BlogPost = ({ classNameExtend, post, BlogStore, isPublic }) => {
    const TitleStore = useContext(TitleStoreContext);

    const DeletePostHandler = async () => {
        if (BlogStore.isBlogDeleting) {
            return NotificationsCenter.ShowWarn("Дождитесь удаления записи поста");
        }

        await BlogStore.deleteBlogPost(post.id);
    }

    const handleClick = (event) => {
        event.preventDefault();
        window.location.hash = post.id;
    }

    return (
        <div className={classNameExtend ? "blog-post s-container highlighted" : "blog-post s-container"} id={post.id}>
            {isPublic && <div className="post-author-info">
                <a className="post-author-name" href={`#members/${post.userId}`} dir="auto">
                    {post.User.login}
                </a>
                <div className="post-author-title">
                    {TitleStore.membersTitlesObj[post.User.login] !== null &&
                    <div className={`member__title-${TitleStore.membersTitlesObj[post.User.login].rarity}`}
                         style={{borderColor: TitleStore.membersTitlesObj[post.User.login].color}}>{TitleStore.membersTitlesObj[post.User.login].title}
                    </div>}
                </div>
            </div>}

            {isPublic ? <a className="post-text" href={`#${post.id}`} onClick={handleClick}>{post.text}</a> : <div className="post-text">{post.text}</div>}

            <div>
                <span className="post-date" title={new Date(Number(post.createdAt)).toLocaleString()}>
                    {new Date(Number(post.createdAt)).toLocaleString("ru", {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric'
                    })}{isPublic &&
                        <div style={{
                                display: "flex",
                                alignItems: "center",
                                columnGap: 5
                            }}>
                        </div>}
                </span>

                {!isPublic &&
                <div className="buttons-group" style={{margin: 0}}>
                    <Button
                        className="button" variant={"outline-secondary"} onClick={DeletePostHandler}>
                        Удалить
                    </Button>
                </div>}
            </div>
        </div>
    );
}

export default BlogPost;