import style from "./style.module.css";
import {useState} from "react";
import { evaluate } from "mathjs";

const Calculator = () => {
    const [text, setText] = useState("");
    const [result, setResult] = useState("");

    const inputTextEvent = (event) => {
        const regexp = new RegExp(/[^a-zA-Z\s()\d\/*+.^-]/, "g");
        const rawText = event.target.value;

        if (rawText.match(regexp)) {
            setResult("0");
        } else {
            try {
                const formattedText = rawText.replace(regexp, "");
                let result = 0;

                try {
                    result = evaluate(formattedText);
                } catch (e) {}

                setResult(typeof result === "object" ? result.value.toLocaleString() : result.toLocaleString());
            } catch (e) {}
        }

        setText(event.target.value);
    }

    return (
        <div className={style.wrapper}>
            <div className={style.title}>
                Calculator
            </div>
            <div className={style.inputBox}>
                <input type="text" onChange={inputTextEvent} value={text} placeholder="2+2*2"/>
            </div>
            <div className={style.result}>
                = {result}
            </div>
        </div>
    );
}

export default Calculator;