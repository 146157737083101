import { makeAutoObservable, runInAction } from "mobx";
import axios from "axios";

// STORES
import MainStore from "../Main/MainStore.js";

// MODULES
import NotificationsCenter from "../Main/NotificationsCenter.js";



class BlogStore {
    isBlogLoading = false;
    isBlogDeleting = false;
    posts = [];
    allPosts = [];
    currentPageMyPosts = 0;
    maxItemsOnPageMyPosts = 5;
    currentPageAllPosts = 0;
    maxItemsOnPageAllPosts = 5;

    constructor() {
        makeAutoObservable(this);
    }

    incrementPageMyPosts = () => {
        runInAction(() => {
            this.currentPageMyPosts = Math.min(this.currentPageMyPosts + 1, this.totalPagesMyPosts - 1);
        });
    }

    decrementPageMyPosts = () => {
        runInAction(() => {
            this.currentPageMyPosts = Math.max(this.currentPageMyPosts - 1, 0);
        });
    }

    get totalPagesMyPosts() {
        return Math.ceil(this.posts.length / this.maxItemsOnPageMyPosts);
    }

    incrementPageAllPosts = () => {
        runInAction(() => {
            this.currentPageAllPosts = Math.min(this.currentPageAllPosts + 1, this.totalPagesAllPosts - 1);
        });
    }

    decrementPageAllPosts = () => {
        runInAction(() => {
            this.currentPageAllPosts = Math.max(this.currentPageAllPosts - 1, 0);
        });
    }

    toStartPage = () => {
        runInAction(() => {
            this.currentPageAllPosts = 0;
        });
    }

    toEndPage = () => {
        runInAction(() => {
            this.currentPageAllPosts = this.totalPagesAllPosts - 1;
        });
    }

    get totalPagesAllPosts() {
        return Math.ceil(this.allPosts.length / this.maxItemsOnPageAllPosts);
    }

    sendBlog = async (text, userId) => {
        return (await axios.post(`${MainStore.SERVER_ADDRESS}/addBlogPost`, {
            text,
            userId
        }, {
            withCredentials: true
        })).data.message;
    }

    updateBlog = async (userId) => {
        if (this.isBlogLoading) {
            return;
        }

        runInAction(() => {
            this.isBlogLoading = true;
        });

        let response = await axios.post(`${MainStore.SERVER_ADDRESS}/getBlogPosts`, {
            userId
        }, {
            withCredentials: true
        });

        if (!response?.data) {
            return;
        }

        let receivedPosts = response.data.posts;

        receivedPosts.forEach(post => {
            post.text = MainStore.textFormatter(post.text);
        });

        runInAction(() => {
            this.posts = receivedPosts;
            this.isBlogLoading = false;
        });
    }

    updateNews = async () => {
        let response = await axios.post(`${MainStore.SERVER_ADDRESS}/getAllPosts`, {}, {
            withCredentials: true
        });

        if (!response?.data) {
            return;
        }

        let receivedPosts = response.data.posts;

        receivedPosts.forEach(post => {
            post.text = MainStore.textFormatter(post.text);
        });

        runInAction(() => {
            this.allPosts = response.data.posts;
        });
    }

    deleteBlogPost = async (blogPostId) => {
        runInAction(() => {
            this.isBlogDeleting = true;
        });

        let response = await axios.post(`${MainStore.SERVER_ADDRESS}/deleteBlogPost`, {
            blogPostId
        }, {
            withCredentials: true
        });

        if (!response?.data) {
            return;
        }

        if (response.data?.message) {
            NotificationsCenter.ShowSuccess(response.message);
        }

        runInAction(() => {
            this.isBlogDeleting = false;
        });
    }
}

export default new BlogStore();