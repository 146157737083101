import {useContext, useEffect} from "react";
import {WheatItemsStoreContext} from "../../../App.js";
import Chart from "../../Main/Chart/Chart.jsx";
import {useParams} from "react-router-dom";
import {observer} from "mobx-react";
import style from "../../Main/Chart/style.module.css";
import {Spinner} from "react-bootstrap";



const ItemChartAmount = observer(() => {
    const WIS = useContext(WheatItemsStoreContext);
    const { itemId } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            // Получаем информацию от сервера
            await WIS.getItemChartData(itemId);
        }

        fetchData();
    }, [WIS, itemId]);

    return (
        <div className={style.chartWrapper}>
            <div className={style.title}>
                <div className={style.chartTitle}>
                    {WIS.itemsLoading
                        ? itemId
                        : WIS.itemChartData.itemName}
                </div>
            </div>

            <div className={style.chart}>
                {WIS.itemsLoading ? <Spinner /> : <Chart data={WIS.itemChartData}/>}
            </div>
        </div>
    );
});

export default ItemChartAmount;